import React from "react";
import Layout from "../components/layout";
import Hero from "../components/hero";
import SEO from "../components/seo";

const ONas = ({ data }) => {
  return (
    <Layout>
      <SEO
        title="FAQ RODO i CRM - co powinieneś wiedzieć? - Prosty CRM"
        description="FAQ RODO i CRM. Co warto wiedzieć? Jak zabezpieczyć dane osobowe klientów? Co zrobi za Ciebie CRM, a co co powinieneś zadbać sam? Sprawdź!"
      />
      <Hero maxHeight="200px" justifyContent="center">
        <h1>FAQ RODO i CRM</h1>
        <h2>Najczęściej zadawane pytania</h2>
      </Hero>
      <div
        style={{
          justifyContent: "flex-start",
          flexDirection: "column"
        }}
        className="container"
      >
        <br />
        <h3>Co to jest RODO?</h3>
        <p>
          RODO, czyli Ogólne Rozporządzenie o Ochronie Danych Osobowych (ang.
          General Data Protection Regulation, GDPR) to rozporządzenie Unii
          Europejskiej, zawierające przepisy, dotyczące ochrony osób fizycznych
          w związku z przetwarzaniem ich danych osobowych. Celem wprowadzenia
          rozporządzenia było ujednolicenie prawa ochrony danych osobowych na
          terenie całej Unii Europejskiej oraz zapewnienie swobodnego przepływu
          danych. Nowe przepisy muszą być stosowane od 25 maja 2018 r.
        </p>

        <br />
        <h3>Czy RODO mnie dotyczy?</h3>
        <p>
          Przepisy RODO dotyczą wszystkich podmiotów, które przetwarzają dane
          osobowe. Tym samym obowiązują każdą firmę, która w procesie obsługi
          klientów, działań marketingowych czy sprzedażowych, przetwarza dane
          osobowe.
        </p>
        <br />
        <h3>Czym są dane osobowe w świetle RODO?</h3>
        <p>
          Zgodnie z art. 4 ust. 1 RODO dane osobowe to informacje o
          zidentyfikowanej lub możliwej do zidentyfikowania osobie fizycznej;
          <br />
          Możliwa do zidentyfikowania osoba fizyczna to osoba, którą można
          bezpośrednio lub pośrednio zidentyfikować, w szczególności na
          podstawie identyfikatora takiego jak:
          <li>imię i nazwisko,</li>
          <li>numer identyfikacyjny,</li>
          <li>dane o lokalizacji,</li>
          <li>identyfikator internetowy</li>
          <li>
            jeden bądź kilka szczególnych czynników określających: fizyczną,
            fizjologiczną, genetyczną, psychiczną, ekonomiczną, kulturową lub
            społeczną tożsamość osoby fizycznej;
          </li>
        </p>

        <br />
        <h3>Czym są "szczególne" kategorie danych osobowych?</h3>
        <p>
          Szczególne kategorie danych osobowych to pojęcie, które zastępuje
          dotychczas obowiązujące „wrażliwe” kategorie danych osobowych (pojęcie
          z polskiej Ustawy o Ochronie Danych Osobowych). Art. 9 ust. 1 RODO
          wymienia dane, które zalicza się do kategorii szczególnych,
          jednocześnie zabraniając ich przetwarzania co do zasady. Są to dane,
          dotyczące:
          <li>Pochodzenia rasowego i etnicznego</li>
          <li>Poglądów politycznych</li>
          <li>Przekonań religijnych i światopoglądowych</li>
          <li>Przynależności do związków zawodowych</li>
          <li>Stanu zdrowia</li>
        </p>

        <br />
        <h3>Kto jest administratorem danych osobowych?</h3>
        <p>
          Zgodnie z unijnym rozporządzeniem, administratorem danych osobowych
          może być każdy podmiot – osoba fizyczna, osoba prawna, organ
          publiczny, jednostka organizacyjna lub jakikolwiek podmiot
          nieposiadający osobowości prawnej. Taki podmiot staje się
          administratorem, jeśli samodzielnie lub wspólnie z innymi podmiotami
          ustala cele i sposoby przetwarzania danych osobowych. Tym samym
          administratorem danych osobowych jest każda firma, w której ustala się
          cele i sposoby przetwarzania danych osobowych.
        </p>

        <br />
        <h3>Kim jest procesor danych osobowych?</h3>
        <p>
          Mianem procesora określa się podmiot przetwarzający dane osobowe. Nie
          decyduje on o celu i sposobie ich przetwarzania, a jedynie o
          narzędziach ku temu służących. Procesor to podmiot, który na zlecenie
          administratora przechowuje, utrwala, organizuje, modyfikuje,
          udostępnia, niszczy czy usuwa dane osobowe.
        </p>

        <br />
        <h3>Jak system CRM chroni dane osobowe?</h3>
        <p>
          Jako dostawcy oprogramowania dla firm bardzo wysoko cenimy sobie
          kwestie bezpieczeństwa danych naszych klientów. W ramach ochrony
          danych dbamy o:
          <ol>
            <li>
              Bezpieczeństwo systemów – nasze systemy wykorzystują
              zabezpieczenia protokołu SSL, które chronią przed nieautoryzowanym
              dostępem do zasobów systemu.
            </li>
            <li>
              Bezpieczeństwo logowania – systemy wymuszają utworzenie silnego i
              unikalnego hasła, blokują też dostęp po kilkukrotnym podaniu
              błędnego hasła.
            </li>
            <li>
              Uprawnienia pracowników – kontrola dostępu dzięki tworzeniu
              poziomów uprawnień dostępu.
            </li>
            <li>
              Monitorowanie przetwarzania danych – systemy zapisują datę i
              godzinę utworzenia kartoteki kontrahenta. Dostępna jest pełna
              historia każdej edycji danych wraz z osobą, która zmian dokonała.
            </li>
            <li>
              Możliwość usunięcia danych z systemu – zgodnie z RODO, każdy
              konsument ma prawo do tego, aby jego dane przestały być
              przetwarzane.
            </li>
          </ol>
        </p>

        <br />
        <h3>Co mogę zrobić, aby zapewnić ochronę danych moich klientów?</h3>
        <p>
          <ol>
            <li>
              Po pierwsze – dbaj o bezpieczeństwo systemu. Nie zapisuj haseł w
              przeglądarce, nie udostępniaj ich osobom postronnym i staraj się
              regularnie zmieniać hasła do systemu jak i do poczty e-mail.
            </li>
            <li>
              Po drugie – zapewnij swoim pracownikom niezbędną wiedzę i
              przeszkolenie w zakresie prawa ochrony danych osobowych.
            </li>
            <li>
              Po trzecie – przechowuj dane w jednym, dobrze chronionym miejscu,
              jakim jest system CRM. Nie trzymaj delikatnych danych na dyskach
              twardych i niezabezpieczonych serwerach. Nie przechowuj danych
              osobowych w otwartych szufladach, szafach czy katalogach.
            </li>
            <li>
              Po czwarte – zbieraj tylko te dane, które są absolutnie niezbędne.
              Minimalizacja danych polega na gromadzeniu danych wyłącznie w
              sytuacji, kiedy są one niezaprzeczalnie potrzebne np. do procesu
              sprzedaży lub działań marketingowych.
            </li>
            <li>
              Po piąte – zapoznaj się z treścią RODO, a nie tylko z publikacjami
              internetowymi.
            </li>
            <li>
              Po szóste – wprowadź własną politykę ochrony danych osobowych.
              Zastanów się, jak możesz zmienić podejście do danych, zapewniając
              klientom pełną ochronę ich tożsamości i zachowując płynność
              procesów sprzedażowych. Zaprojektuj własny, wewnętrzny zbiór
              zasad, dotyczących przetwarzania danych osobowych, który będzie
              zgodny z przepisami prawa i wartościami wyznawanymi przez Twoją
              firmę.
            </li>
          </ol>
        </p>

        <br />
        <h3>Jakie kary grożą za nieprzestrzeganie RODO?</h3>
        <p>
          Środki administracyjne:
          <ol>
            <li>
              Ostrzeżenia dla administratora bądź podmiotu przetwarzającego
            </li>
            <li>
              Upomnienia dla administratora bądź podmiotu przetwarzającego
            </li>
            <li>
              Nakaz spełnienia żądania osoby, której dane dotyczą, wynikającego
              z praw przysługujących jej na mocy rozporządzenia (np. prawo do
              bycia zapomnianym)
            </li>
            <li>Zakaz lub ograniczenie przetwarzania danych</li>
            <li>
              Inne – m. in. cofnięcie certyfikacji lub nakaz zawiadomienia
              osoby, której dane naruszono.
            </li>
          </ol>
          <br />
          Administracyjne kary pieniężne:
          <ol>
            <li>
              Kary pieniężne w wysokości do 10 mln euro, lub 2 % całkowitego
              rocznego obrotu światowego przedsiębiorstwa z poprzedniego roku.
            </li>
            <li>
              Nieprzestrzeganie nakazu orzeczonego przez organ nadzorczy (art.
              58. ust. 2.) podlega administracyjnej karze w wysokości do 20 mln
              euro, lub 4% całkowitego rocznego obrotu światowego
              przedsiębiorstwa z poprzedniego roku.
            </li>
          </ol>
          <br />W rzeczywistości jednak kary będą ustalane wedle skali,
          charakteru i indywidualnej sytuacji każdego podmiotu. Nie należy się
          więc spodziewać wysokich kar pieniężnych w przypadku niewielkich
          uchybień. Nie mniej jednak zabezpieczenie danych osobowych powinno być
          priorytetem.
        </p>

        <br />
        <h3>Jak zarządzać uprawnieniami dostępu do danych osobowych?</h3>
        <p>
          Uprawnienia w systemie CRM pozwalają na zarządzanie informacjami, do
          których mają dostęp poszczególni pracownicy. System pozwala na
          utworzenie różnych profili np. profil „kierownik” lub „specjalista”.
          Do każdego profilu przydzielane są uprawnienia, a na ich podstawie
          użytkownik ma dostęp do wybranych lub wszystkich informacji w
          systemie. Dostęp do danych osobowych powinni mieć tylko uprawnieni
          pracownicy, których charakterystyka pracy wymusza korzystanie z nich.
        </p>
      </div>
    </Layout>
  );
};

export default ONas;
